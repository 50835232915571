.campaignContainer {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.campaignViews {
  margin-bottom: 1.5rem;
  font-family: Gilroy-Medium;
}

.campaignViews h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.campaignViews p {
  font-size: 1rem;
  color: #6b7280;
  line-height: 10px;
}

.CreatorDetailsBox {
  margin-top: 1.5rem;
}

.CreatorDetail {
  font-family: Gilroy-Medium;
}

.creatorItem {
  background: white;
  transition: all 0.3s ease;
  list-style: none;
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
}

.creatorItem:hover {
  background-color: #ff006b;
  border-radius: 25px;
  color: white;
  cursor: pointer;
}

/* Highlight selected creator */
.selectedCreator {
  background-color: #ff006b; /* Highlight color */
  color: white; /* Text color */
  border-radius: 25px;
}

.creatorLogo {
  width: 25px;
  border-radius: 50%;
  border: 1px solid #727272;
  margin-right: 0.5rem;
}

.campaignStats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.statItem {
  padding: 0.75rem;
  background: #f9fafb;
  border-radius: 6px;
}



.statItem p {
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.statItem strong {
  font-size: 1rem;
  color: #111827;
}


.rotateIcon {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.searchFilterContainer {
 
  
 
  margin: 10px;
}

.searchFilterWrapper {
  display: flex;
  gap: 1rem;
  
  border-radius: 12px;
  align-items: center;
}

.searchInput {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid ;
  border-radius: 4px;
 border-radius: 24px;
  font-family: Gilroy-Medium;
  padding-left: 12px;
  font-size: 0.9rem;
}

.nicheSelect {
  padding: 0.5rem;
  border: 1px solid;
  border-radius: 4px;
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-size: 0.9rem;
  min-width: 150px;
}

.searchInput:focus,
.nicheSelect:focus {
  outline: none;
  border-color: var(--primary-color);
}