@keyframes appear{
  from{
    opacity: 0;
    transform:translateX(-200px) ;
  }
  to{
    opacity: 1;
    transform:translateX(0px) ;
  }
}

.brands-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 40px;
  font-family: Gilroy-Bold;

  background: rgba(0, 68, 255, 0);
  animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
}

.brands-section h1 {
  font-size: 24px;
  margin-bottom: 30px;
}

.brands-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.brand-logo {
  width: 100px;
  height: auto;
}

.brand-logo img {
  width: 80px;
}

/* Mobile View  ////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .brand-logo {
    width: 50px;
    height: auto;
  }
  .brand-logo img {
    width: 40px;
  }
}
