/* Description.module.css */

.descriptionBox {
    padding: 20px;
    border: 1px solid #363636;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    color: black;
  }
  
  .section {
    margin-bottom: 20px;
  }

  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popupContent {
   
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width:90%;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .clickable {
    cursor: pointer;
  }
  
  .non-clickable {
    cursor: default; /* or use cursor: auto; */
  }

  .connectBtn{
    background-color: #ff007f;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 15px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    color: red;
  }
  .instructions {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .steps {
    text-align: left;
  }
  
  .steps h3 {
    color: #4CAF50;
  }
  
  .steps ol {
    padding-left: 20px;
    color: #333;
  }
  
  .steps ol li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  .steps ul {
    list-style-type: disc;
    padding-left: 40px;
    color: #555;
  }
  
  .steps ul li {
    margin-bottom: 8px;
    line-height: 1.4;
  }
  
  .SocialIcon{
    width: 100%;
    display: flex;
    justify-content: left;
   gap: 16px;
    flex-direction: row;
  }
  
 h3,h4 {
    font-family: Gilroy-Bold;
    font-size: 18px;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
  
 .descriptionBox p {
    font-family: Gilroy-Medium;
    font-size: 14px;
    color: #090909;
    margin: 5px 0;
  }
  
  .descriptionBox .highlightedText {
    color: #ff007f;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .dot {
    color: #888;
    margin: 0 5px;
  }
  
  .genreTags {
    display: flex;
    flex-wrap: wrap;

  }
  
  .genreTags span {
    font-family: Gilroy-Medium;
    background-color: #f7f7f700;
    border: 1px solid #898989;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #0f0f0f;
  }

  /*    */
  .creatorPic{
    width: 25px;
    border-radius: 50%;
    border:1px solid #727272;
    margin-right: 0.5rem;
  }
  

  @media (max-width: 480px) {
    .descriptionBox {
      padding: 15px;
      width: 100%;
    }

    .popupContent {
      font-family: Gilroy-Medium;
      font-size: 18px;
      width: 95%;
      padding: 15px;
    }
    .popupContent h3 {
      font-size: 18px;
    }
    
    .popupContent p{
      font-size: 18px;
    }

    .connectBtn {
      padding: 6px 10px;
      margin-right: 10px;
    }

    .instructions {
      font-size: 16px;
      width: 90%;
      padding: 15px;
    }
    .instructions p{
      font-size: 18px;
    }
    .steps h3 {
      font-size: 16px;
    }

    .steps ol li,
    .steps ul li {
      font-size: 12px;
    }

    .SocialIcon {
      gap: 10px;
    }

    h3, h4 {
      font-size: 16px;
    }

    .descriptionBox p {
      font-size: 12px;
    }

    .genreTags span {
      font-size: 12px;
      padding: 4px 8px;
    }
  }
  
  .usernameContainer {
    position: relative;
    display: inline-block;
    background-color: #ff007f;
    padding: 10px 10px;
    color:white;
    border-radius: 25px;
  }
  
  .tooltip {
    visibility: hidden;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 14px;
    white-space: nowrap;
    
    /* Position the tooltip */
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
    
    /* Add a small triangle */
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    }
  }
  
  .usernameContainer:hover .tooltip {
    visibility: visible;
  }