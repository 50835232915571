/* BrandsInfo.module.css */

.brandsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  padding: 20px;
}

.brandBox {
  border-radius: 8px;

  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandLogo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/*    Review section*/

.reviewsTitle {
  font-family: Gilroy-Bold;
  font-size: 1.5em;
  margin-bottom: 10px;
  padding: 5px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  display: inline-block;
  background-color: #f9f9f9;
}

.reviewsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.reviewCard {
  background-color: #fff;
  border: 1px solid #595959;
  border-radius: 10px;
  padding: 15px;
  max-width: 300px;
  flex: 1;
}

.reviewHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: Gilroy-Bold;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.reviewName {
  font-family: Gilroy-Bold;
  font-size: 1.1em;
  margin: 0;
  color: #000000;
  font-weight: bold;
}

.reviewBrand {
  color: #000000;
  margin: 0;
  font-family: Gilroy-Medium;
}

.reviewText {
  font-size: 0.9em;
  margin: 10px 0;
  color: #555;
  font-family: Gilroy-Medium;
}

.reviewFooter {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #f39c12;
}

.star {
  margin-right: 5px;
}
