.black {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%; /* Ensure it takes the full width of the viewport */
  justify-content: space-around;
  align-items: center;

  position: relative; /* Ensure the section is positioned relative for child elements */
  overflow: hidden; /* This ensures content doesn't overflow the section */
}

.text-wrap {
  position: relative;
  overflow: hidden;
  width: 50%;

  height: 100%;
  margin: 50px;
}

.blue-text,
.red-text,
.orange-text,
.purple-text {
  height: 60%;
}

.panel-text {
  position: absolute;

  z-index: 1;
  width: 100%;

  font-size: 40px;
  font-family: Gilroy-Medium;
  font-weight: 900;
  text-align: left;

  transform: translateY(70%);
  opacity: 0;
 
}

.panel-text h1 {
  font-size: 40px;
  width: auto;
  color: rgb(18, 18, 18);
  
  font-weight: bold;
  border-radius: 15px;
}

.panel-text p {
  font-size: 20px;
  font-weight: 200;
  color: rgb(5, 5, 5);
}

.p-wrap {
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 50%;
  border: 2px solid #ff0066a7;
  background-color: #ff0066;
  border-radius: 24px;

  margin: 50px;
}

.panel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.panel.blue {
  background-image: url("./images/BrandProfile.png");
}

.panel.red {
  background-image: url("./images/CampaignView.png");
}

.panel.orange {
  background-image: url("./images/ChatPage.png");
}

.panel.purple {
  background-image: url("./images/RequestPage.png");
}

/* Mobile view ////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media (max-width: 480px) {
  .black {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
    width: 100%; /* Ensure it takes the full width of the viewport */
    justify-content: space-around;
    align-items: center;
   
    position: relative; /* Ensure the section is positioned relative for child elements */
    overflow: hidden; /* This ensures content doesn't overflow the section */
  }
  .text-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .p-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 30%;
    border-radius: 24px;
    margin: 10px;
  }

  .blue-text,
  .red-text,
  .orange-text,
  .purple-text {
    height: 50%;
  }
}
