.BrandContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px 0;
}

.BrandHeroPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.BrandHeroPage h1 {
  font-size: 60px;

  color: #000;
  margin-bottom: 20px;

  text-align: center;
  font-family: Gilroy-Medium;
}

.BrandHeroPage p {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin: 0 200px 40px 200px;
}

.BrandHeroPage .StartButton1 {
  background-color: #ff0066;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}

.StartButton:hover {
  background-color: #a3013f;
}

.GetInTouch {
  margin-top: 30px;
}

.GetInTouch p {
  font-size: 16px;
  color: #666;
}

.GetInTouch a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.GetInTouch a:hover {
  text-decoration: underline;
}
/* Brand Section//////////////////////////////////////////////*/

.BrandIcons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  font-family: Gilroy-Bold;
  margin: 50px 0;
  background: rgba(0, 68, 255, 0);
}
.brands-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.brand-logo img {
  width: 80px;
}

/* Feedback Section/////////////////////////////////////////////////////////////////////////*/
.feedbackSection {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
  background-color: #86868600;
  font-family: "Arial", sans-serif;
}
.LeftPanel {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 248, 255, 0);
}
.feedbackPanel {
  width: 60%;
  background-color: #171717;
  border-radius: 15px;
  padding: 20px;
  color: #fff;
  font-family: Gilroy-Medium;
  box-shadow: 0 4px 8px rgba(102, 102, 102, 0.1);
}
.feedbackPanel:hover {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.564);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.toggleSwitch {
  position: relative;
}

.switchInput {
  display: none;
}

.switchLabel {
  width: 40px;
  height: 20px;
  background-color: #ea0063;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.switchLabel::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.3s;
}

.switchInput:checked + .switchLabel::after {
  transform: translateX(20px);
}

.feedbackList {
  margin-top: 20px;
}

.feedbackItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.userDetails {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
}

.userDetails .userName {
  flex-grow: 1;
  color: white;
}

.status {
  color: #888;
  margin-left: auto;
  text-align: right;
}
.addmoreBtn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.addmoreText p {
  margin-top: 10px;
  color: orangered;
}
.addMoreBtn {
  background-color: #ff0066;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.RightPanel {
  width: 50%;
}
.feedbackInfo {
  width: 90%;
}

.feedbackTag {
  background-color: #ff0066;
  color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}

.feedbackInfo h2 {
  font-size: 50px;
  color: #000;
  font-family: Gilroy-Medium;
  font-weight: bold;
  margin-bottom: 20px;
}

.feedbackInfo p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.howItWorksBtn {
  background-color: transparent;
  color: #ff0066;
  border: none;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.howItWorksBtn:hover {
  text-decoration: underline;
}

/*  Dashbaord View left and right panel //////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////**/
.DashBoardView {
  display: flex;
  padding: 50px 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.LeftPanelDash {
  width: 50%;
  height: 100%;
  padding-right: 30px;
}

.shareButton {
  background-color: #ff0066;
  color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 10px;
  border: none;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}

.mainHeading {
  font-size: 50px;
  color: #000;
  font-family: Gilroy-Medium;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;

  color: #666;
  margin: 10px 0;
}

.howItWorksBtn {
  background-color: transparent;
  color: #ff0066;
  border: none;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.howItWorksBtn:hover {
  text-decoration: underline;
}


/* Right Panel */
.RightPanelDash {
  width: 50%;

  background: rgb(238, 238, 238);
  padding: 30px;
  border-radius: 24px;
}
.RightPanelDash img {
  width: 100%;
}

/*Metrix Box ????????????????????????????????????????????????????????????????????? */
.MetrixContainer {
  margin: 50px 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  background-color: #a3013f00;
}
.MetrixContainer h1 {
  margin-bottom: 20px;
  font-family: Gilroy-Medium;
}
.MetrixBox {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: #33333300;
  width: 100%;

  gap: 50px;
}
.metricCard {
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid rgba(91, 91, 91, 0.393);
  padding: 20px;
  text-align: center;
  max-width: 400px;
  height: 305px;
  transition: 0.3s ease-out;
}
.metricCard:hover {
  background-color: #ffffff;
}

.metricCard h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #000;
}

.metricCard p {
  color: #666;
  margin-bottom: 20px;
}

.metricImage {
  width: 100%;
  height: auto;
}

.metricImage img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Brand Says Section //////////////////////////////////////////////////////*************************************/
.BrandSaysContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 50px 100px;

  flex-wrap: wrap;
  background: rgb(230, 230, 230);
}

.testimonial {
  width: 50%;
  height: 400px;
  padding: 20px;

  border-radius: 10px;

  transition: 0.3s ease;
}

.companyLogo {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.testimonialText {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.userDetails {
  display: flex;
  align-items: start;
  justify-content: left;
}
.userDetails .Companyser {
  display: flex;
  flex-direction: column;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial .userName {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.userTitle {
  font-size: 14px;
  color: #888;
}

/*Newsletter section ************************************************************************************** */
.Newsletter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  Mobile view ///////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .BrandHeroPage {
    /* Takes the full width of the viewport */

    padding: 10px; /* Adds padding to prevent content touching screen edges */
    margin: 40px auto; /* Center the content */
    height: auto;
  }

  .BrandContainer {
    margin: 0 auto;
  }

  .BrandHeroPage h1 {
    font-size: 30px;
  }
  .BrandHeroPage p {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin: 0 10px 20px 10px;
  }

  /* feedback *****************************************/
  .feedbackSection {
    padding: 10px;
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .LeftPanel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 248, 255, 0);
  }
  .RightPanel {
    width: 100%;
    padding: 10px 30px;
  }

  .feedbackPanel {
    width: 90%;
    background-color: #171717;
    border-radius: 15px;
    padding: 20px;
    color: #fff;
    font-family: Gilroy-Medium;
    box-shadow: 0 4px 8px rgba(102, 102, 102, 0.1);
  }

  .feedbackInfo h2 {
    font-size: 30px;
  }

  .metricCard {
    height: 250px;
  }

  .BrandSaysContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 40px;
    width: 100%;
    flex-wrap: wrap;
    background: rgb(230, 230, 230);
  }
  .testimonial {
    width: 90%;
    height: auto;
  }

  /*  Dashboard Mobile View*/
  .DashBoardView {
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .LeftPanelDash {
    width: 100%;
    height: 100%;
    padding-right: 0px;
  }

  .shareButton {
    background-color: #ff0066;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 10px;
    border: none;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
  }

  /* Right Panel */
  .RightPanelDash {
    width: 100%;
    margin-top: 20px;
    background: rgb(238, 238, 238);
    padding: 30px;
    border-radius: 24px;
  }
  .RightPanelDash img {
    width: 100%;
  }

  /* Brand Says Section //////////////////////////////////////////////////////*************************************/

  .testimonial {
    width: 100%;
    height: auto;
  }
}
