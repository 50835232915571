@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Gilroy-Bold;
  src: url('./Fonts/Gilroy-Bold.ttf');
}

@font-face {
  font-family: Gilroy-Light;
  src: url('./Fonts/Gilroy-Light.ttf');
}

@font-face {
  font-family: Gilroy-Medium;
  src: url('./Fonts/Gilroy-Medium.ttf');
}

@font-face {
  font-family: Gilroy-Heavy;
  src: url('./Fonts/Gilroy-Heavy.ttf');
}
 
