/* Newsletter-section /////////////////////////////////////////////////////////////////////////////////////////////*/
.newslettersection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 50px;
    font-family: Gilroy-Bold;
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  }
  
  .newslettersection h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
  }
  
  .newslettersection p {
    font-size: 16px;
    color: #0a0a0a;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .newsletterform {
    display: flex;
    flex-direction: row;
  }
  
  .newsletterform input[type="email"] {
    padding: 10px 20px;
    border: 1px solid #ff0066;
    border-radius: 10px;
    font-size: 16px;
    width: 300px;
    font-family: Gilroy-Medium;
    outline: none;
    margin-right: 10px;
    color: #666;
    margin-bottom: 0;
  }
  
  .newsletterform input[type="email"]::placeholder {
    color: #888;
  }
  
  .newsletterform button {
    padding: 10px 20px;
    background-color: #ff0066;
    font-family: Gilroy-Medium;
    border: 1px solid #ff0066;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .newsletterform button:hover {
    background-color: #e60059;
  }



  /*  Newsletter container*/
  @media (max-width: 480px) {
  .newslettersection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 10px;
    font-family: Gilroy-Bold;
    
  }
  
  .newsletterform {
    display: flex;
    flex-direction: column;
  }

  .newsletterform button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff0066;
    font-family: Gilroy-Medium;
    border: 1px solid #ff0066;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}