/* CampaignPerformance.module.css */
.campaignContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 1px solid rgba(55, 55, 55, 0.558);
    border-radius: 8px;
    padding: 20px;
}
.CreatorDetailsBox{
    width: 100%;
    flex-direction: column;
    display: flex;
    
}
.campaignViews{
    width: 100%;
 align-items: center;
  justify-content: space-between;
  padding: 10px;
    display: flex;
}

.AllTime{
    border: 1px solid rgba(103, 103, 103, 0.568);
    padding: 0 5px;
    border-radius: 5px;
}
.CreatorDetail{
  width: 100%;
 align-items: center;
  justify-content: space-between;
  padding: 10px;
    display: flex;
    
}
.CreatorBasics{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.CreatorBasics img{
    width: 50px;
}
.CreatorName{
 display: flex;

 flex-direction: column;
 margin-left: 12px;
}
.CreatorName p{
    margin-bottom: 5px;
}
.CreatorViews{
    font-family: Gilroy-Medium;
}