.dashboardContainer {
  padding: 20px 40px;
  font-family: Arial, sans-serif;
}
.chartContainer {
  flex: 3;
  border-radius: 8px;
  padding: 20px;
}

.performanceContainer {
  flex: 2;
  border-radius: 8px;
  padding: 20px;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Gilroy-Medium;
  margin-bottom: 20px;
}

.dashboardHeader h1 {
  font-size: 24px;
  font-weight: bold;
}


.btnColor{
  background-color: #ff006b;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}

.dateDownload {
  display: flex;
  align-items: center;
}

.dateRange {
  background-color: #f4f4f4;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 10px;
}

.downloadButton {
 
  color: #ff006b;
  padding: 8px 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
}
.downloadButton:hover {
  background-color: #f4f4f4;
  color:black;
}

.dashboardNav {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 4px;
}

.navItem {
  padding: 5px 10px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #888;
  border-radius: 6px;
  margin-right: 4px;
}

.navItem:hover {
  color: #000;
}

.navItem.active {
  background-color: #fff;
  font-weight: bold;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navItem:not(.active):hover {
  color: #333;
}

@media (max-width: 480px) {
  .dashboardContainer {
    padding: 5px 8px;
  }
  .dashboardHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  
}
