/* Statistics Section */
@keyframes appear{
  from{
    opacity: 0;
    scale: 0.5;
  }
  to{
    opacity: 1;
    scale: 1;
  }
}

.stats-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 50px;
    font-family: Gilroy-Bold; 
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0;
  }
  .stats-section img{
    width: 80%;
    height: auto;
  }

  /* Mobile View  ////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .stats-section {
    margin: 50px 10px;
  }
  .stats-section img{
    width: 100%;
  
  }
}