.campaignContainer {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.campaignViews {
  margin-bottom: 1.5rem;
  font-family: Gilroy-Medium;
}

.campaignViews h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.campaignViews p {
  font-size: 1rem;
  color: #6b7280;
  line-height: 10px;
}

.CreatorDetailsBox {
  margin-top: 1.5rem;
}
.CreatorDetail {
  font-family: Gilroy-Medium;
}

.creatorItem {
  background-color: #ff006b;
  transition: all 0.3s ease;
  cursor: pointer;
  list-style: none;
  border-radius: 15px;
  color: white;
}

.creatorItem:hover {
  background-color: #ff006b;
  border-radius: 20px;
}
.reelLink {
  background-color: #ff006b;
  cursor: pointer;
  padding:10px;
  margin-top: 10px;
  border-radius: 15px;
  color: white;
}
.reelLink a{
  margin-top: 5px;
}
.refreshIcon {
  position: absolute;
  right: 0; /* Keeps it aligned to the right */
  top: 50%; /* Vertically centers it */
  transform: translateY(-50%); /* Ensures perfect vertical centering */
  font-size: 2rem;
  color: #666;
  margin-right: 10px; /* Optional: Adds slight spacing from the edge */
}
.creatorLogo {
  width: 25px;
  border-radius: 50%;
  border: 1px solid #727272;
  margin-right: 0.5rem;
}
.campaignStats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.statItem {
  padding: 0.75rem;
  background: #f9fafb;
  border-radius: 6px;
}

.statItem p {
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.statItem strong {
  font-size: 1rem;
  color: #111827;
}
