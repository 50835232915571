/* Feedback Section/////////////////////////////////////////////////////////////////////////*/
.feedbackSection {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px;
    background-color: #86868600;
    font-family: "Arial", sans-serif;
  }
  .LeftPanel {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 248, 255, 0);
  }
  .feedbackPanel {
    width: 60%;
    background-color: #171717;
    border-radius: 15px;
    padding: 20px;
    color: #fff;
    font-family: Gilroy-Medium;
    box-shadow: 0 4px 8px rgba(102, 102, 102, 0.1);
  }
  .feedbackPanel:hover {
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.564);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggleSwitch {
    position: relative;
  }
  
  .switchInput {
    display: none;
  }
  
  .switchLabel {
    width: 40px;
    height: 20px;
    background-color: #ea0063;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  .switchLabel::after {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.3s;
  }
  
  .switchInput:checked + .switchLabel::after {
    transform: translateX(20px);
  }
  
  .feedbackList {
    margin-top: 20px;
  }
  
  .feedbackItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .userDetails {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
  }
  
  .userDetails .userName {
    flex-grow: 1;
    color: white;
  }
  
  .status {
    color: #888;
    margin-left: auto;
    text-align: right;
  }
  .addmoreBtn {
    
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .addmoreText p {
    margin-top: 10px;
    color: orangered;
  }
  .addMoreBtn {
    background-color: #ff0066;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }
  
  .RightPanel {
    width: 50%;
  }
  .feedbackInfo {
    width: 90%;
  }
  
  .feedbackTag {
    background-color: #ff0066;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .feedbackInfo h2 {
    font-size: 50px;
    color: #000;
    font-family: Gilroy-Medium;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .feedbackInfo p {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .howItWorksBtn {
    background-color: transparent;
    color: #ff0066;
    border: none;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .howItWorksBtn:hover {
    text-decoration: underline;
  }
  

  /*  Mobile view ///////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {

/* feedback *****************************************/
.feedbackSection {
    padding: 10px;
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .LeftPanel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 248, 255, 0);
  }
  .RightPanel {
    width: 100%;
    padding: 10px 30px;
  }

  .feedbackPanel {
    width: 90%;
    background-color: #171717;
    border-radius: 15px;
    padding: 20px;
    color: #fff;
    font-family: Gilroy-Medium;
    box-shadow: 0 4px 8px rgba(102, 102, 102, 0.1);
  }

  .feedbackInfo h2 {
    font-size: 30px;
  }
}