/* Overview.module.css */
.overviewContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(55, 55, 55, 0.558);

  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardContent p {
  margin: 0;
  font-family: "Gilroy-Medium";
}

.cardContent h3 {
  margin: 10px 0;
  font-family: "Gilroy-Bold";
}

.redText {
  color: #e53935;
}

.blackText {
  color: #000;
}

.subText {
  color: #888;
  font-size: 12px;
}

.icon {
  font-size: 24px;
  color: #aaa;
}

.ChartNPerform {
  display: flex;

  gap: 20px;
  margin-top: 20px;
  width: 100%;
}
.chartContainer {
  width: 60%;
}

.performanceContainer {
  width: 40%;
}
@media (max-width: 768px) {
  .overviewContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .overviewContainer {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .card {
    padding: 15px;
  }

  .ChartNPerform {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
  .chartContainer {
    width: 100%;
  }

  .performanceContainer {
    width: 100%;
  }
}
