.admin-container {
  display: flex;
  flex-direction: row;
  height: auto;
  
}

.admin-left, .admin-right {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.admin-left {
  background-color: #f0f0f0; /* Example background color */
}

.admin-right {
  background-color: #ffffff; /* Example background color */
}

form {
  display: flex;
  flex-direction: column;
  height: auto;
}

button {
  align-self: flex-start;
}