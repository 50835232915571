
.footerContainer {
  width: 100%;
  height: auto;
  display: flex;
  
  padding: 40px;
  flex-direction: row;
  border-top: 1px solid #e6e6e6;
}

.footerSectionLeft {
  width: 40%;
  padding: 0 80px;
}

.footerSectionLeft p {
  text-align: left;
  color: #000000;
  line-height: 1.5;
}
.footerSectionRight {
  width: 60%;
  display: flex;
  flex-direction: row;
}
.footerSection {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
}

.footerTitle {
  font-family: Gilroy-Medium;
  margin-bottom: 10px;
}

.SocialIcon{
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  
  height: 30px;
}
.Icon{
 
  
  color:rgb(14, 14, 14);
  font-size: 24px;
}
.Icon:hover{
  color: #ea0063;
}



.footerText {
  color: #000000;
  font-size: 14px;
  line-height: 1.5;
  font-family: Gilroy-Medium;
}

.footerList {
  list-style: none;
  padding: 0;
}

.footerItem {
  color: #000000;
  font-size: 14px;
  font-family: Gilroy-Light;
  margin-bottom: 10px;
}

.footerItem:hover {
  text-decoration: underline;
  cursor: pointer;
}

.FooterBottom {
  background: #ea0063;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  color: white;
}

.footerLink,
.footerBtn,
.footerName {
  display: flex;
  flex-direction: row;
  flex: 1;

  justify-content: center;
  align-items: center;

}
.footerLink div{
  display: flex;
  flex-direction: row;
}

.FooterBottom p {
  color: white;
}

.footerBtn div {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.footerLink div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.footerTitle img {
  width: 100px;
}

@media (max-width: 768px) {
}

/* Mobile View  ////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .footerContainer {
    width: 100%;
    height: auto;
    display: flex;
    
    padding: 10px 0px;
    flex-direction: column;
    border-top: 1px solid #e6e6e6;
  }
  

  .footerSectionLeft {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
  }

  .footerSectionRight {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
