/* About Section*/
@keyframes left{
  from{
    opacity: 0;
    transform:translateX(-200px) ;
  }
  to{
    opacity: 1;
    transform:translateX(0px) ;
  }
}
@keyframes right{
  from{
    opacity: 0;
    transform:translateX(200px) ;
  }
  to{
    opacity: 1;
    transform:translateX(0px) ;
  }
}

.about-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 70px 100px;
  font-family: Gilroy-Bold;
 
}

.info-box {
 width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: left  linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.info-item {
  display: flex;
  
  margin-bottom: 20px;
}

.icon {

  
  margin-right: 20px;
}

.icon .icon-image {
  color: #ff006b;
  font-size: 40px;
 
}

.stat-item .icon-image {
  color: #ff006b;
}

.info-content h3 {
  font-size: 20px;
 
  margin-bottom: 0px;
  color: #000;
}

.info-content p {
  margin-top: 10px;
  font-size: 14px;
  color: #1a1a1a;
}

.stats-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: right linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.stats-box-inner {
  display: flex;

  margin: 0 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px;
  border: 1px solid rgba(61, 61, 61, 0.311);
  border-radius: 20px;
}

.stat-item {
  width: 40%;
  margin-bottom: 20px;
}

.stat-item h3 {
  font-size: 28px;
 
  margin: 0;
  color: #000;
}

.stat-item p {
  font-size: 14px;
  margin-bottom: 12px;
  color: #151515;
}

.stat-item span {
  font-size: 12px;
  color: #191919;
}

/* Mobile View  ////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .about-section {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
    margin: 50px 20px;
   
  }
  .info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .info-item {
    display: flex;
   flex-direction: row;
    width: 100%;
  }

  .stat-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #000;
  }

  .stats-box-inner {
    display: flex;
  
    margin: 0 0px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(61, 61, 61, 0.311);
    border-radius: 20px;
  }
}
