/* InfluencerProfile.module.css */

.influencerProfile {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Banner.module.css */

.banner {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(50%);
}

.profileSection {
  display: flex;
 
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;

  padding: 50px 20px;
  background: rgba(30, 30, 30, 0.155);
  border-radius: 8px;
}


.profileImage {
  width: 150px;
  height: 150px;

  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border:1px solid red;
}

.profileImage:hover{
  border:2px solid rgb(61, 213, 31);
  cursor: pointer;
}



.profileDetails {
  color: white;
  flex: 1;
}

.profileDetails h1 {
  font-family: Gilroy-bold;
  margin: 0;
  font-size: 24px;
}

.profileDetails p {
  margin: 5px 0;
  font-size: 16px;
  color: white;
  font-family: Gilroy-Medium;
}

.extraDetails {
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0;
  padding-left: 50px;
  color: white;
  padding: 30px;
  background: rgba(239, 239, 239, 0.148);
}
.extraDetails span {
  margin: 0 50px;
  font-family: Gilroy-Medium;
}
.ratingLocation {
  font-family: Gilroy-Medium;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.rating {
  font-size: 14px;
  margin-right: 10px;
}

.location {
  font-size: 14px;
}

.contactViews {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-family: Gilroy-Medium;
}

.contactButton {
  background-color: #ff007f;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
}

.views {
  font-size: 14px;
  font-family: Gilroy-Medium;
}

.profileContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
}

.profileImageContainer {
  flex-shrink: 0;
  margin-right: 20px;
}

.profileDetails .location {
  margin: 5px 0;
  color: #777;
}

.ButtonDiv{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
}

.ModelButton{
  padding: 10px 20px;
  background-color: #ff007f; /* You can change to your desired color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}




/*   //////////////////////////////////////////////*/
.stats {
  margin-top: 10px;
}

.stats span {
  display: block;
  margin-top: 5px;
  color: #555;
}



.BelowContainer {
  display: flex;
  flex-wrap: wrap;
  
}

.descriptionContainer {
  display: flex;
  width: 40%;

  justify-content: space-between;
  padding: 20px;
}

.toggleContainer {
  width: 50%;
  font-family: Gilroy-Medium;
  margin: 20px;
  border-radius: 8px;
}

.toggleButtons {
  display: inline-flex;
  border-radius: 24px;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 2px;
}

.toggleButtons button {
  border: none;
  outline: none;
  padding: 10px 20px;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggleButtons button:first-child {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.toggleButtons button:last-child {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.toggleButtons button.activeButton {
  background-color: white;
  color: black;
}

.toggleButtons button:not(.activeButton) {
  color: #a0a0a0;
}

.toggleButtons button:hover:not(.activeButton) {
  background-color: #e0e0e0;
}

/* .pastCampaigns,
.brands {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
} */

/* Add more styles for the campaign and brand cards */


@media (max-width: 480px) {

  .banner {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
  }
  

  .profileImage {
    width: 100px;
    height: 100px;
  
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
  }

  .extraDetails {
    display: flex;
    justify-content: left;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 0;
 
    color: white;
    padding: 10px;
    background: rgba(239, 239, 239, 0.148);
  }

  .extraDetails span {
    
    margin: 0 0px;
  }

  .descriptionContainer {
   
   width: 100%;
  
  }

  .toggleContainer {
    width: 100%;
  
    margin: 20px;
    border-radius: 8px;
  }

}