.headerContainer {
  width: 100%;
  top: 0;
  font-family: Gilroy-Bold;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 15px 0;
  z-index: 2;
  border-bottom: 1px solid rgba(128, 128, 128, 0.426);
 
}

.headerContent {
  width: 100%;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  width: 50px;
  height: 31.78px;
}

.menuIcon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #272727;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
 
}

.navLink {
  color: black;
 
  text-decoration: none;
  font-weight: normal;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #ff006b; 
  ;
}

.active {
  color: #ff006b; 
 text-decoration: none;
 
}

.GetinTouch {
  padding: 9px 17px;
  background-color: white;
  border: 1px solid #ff006b;
  border-radius: 8px;
  font-size: 16px;
  font-family: Gilroy-Bold;
  cursor: pointer;
  color: #ff006b;
  transition: 0.3s ease;
}

.GetinTouch:hover {
  background-color: #ff006b;
  color: white;
}

.signFormButton {
  padding: 10px 18px;
  background-color: #ff006b;
  color: white;
  border: none;
  margin-right: 5px;
  border-radius: 8px;
  font-size: 16px;
  font-family: Gilroy-Bold;

  cursor: pointer;
  transition: 0.3s ease;
}
.userProfileButton {
  font-size: 30px;
  font-family: Gilroy-Bold;
  cursor: pointer;
  transition: 0.3s ease;
}
.userProfileButton:hover{
  color: #ff006b;
}
.signFormButton:hover {
  background-color: #9c0041;
}

.CampaignButton{
  border: none;
  background: white;
  
}


.dropdownContainer {
  position: relative;
  display: inline-block;
  
}




.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0%;
  
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdownItem {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  text-align: left;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}
/*  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 1024px) {
  .headerContent {
    padding: 0 20px;
    z-index: 2;
  }

  .navigation {
    gap: 20px;
  }

  .navLink {
    font-size: 14px;
  }
}
/* 768px ///////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 768px) {
  .headerContent {
    z-index: 2;
  }
  .menuIcon {
    display: block;
  }

  .navigation {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
  }

  .navigation.showNav {
    display: flex;
    /* Adjust based on the number of links */
    opacity: 1;
  }

  .navigation.hideNav {
    display: flex;
    max-height: 0;
    opacity: 0;
  }

  .navLink,
  .signFormButton {
    width: 100%;
    padding: 1rem;
    text-align: center;
  }

  .navLink:last-child,
  .signFormButton {
    border-bottom: none;
  }
}
/* mobile view //////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .headerContent {
    padding: 12px;
    z-index: 2;
  }

  .menuIcon {
    font-size: 20px;
    margin-right: 12px;
  }
  .navLink {
    padding: 0;
  }
  .signFormButton {
    background-color: #ff006b;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 12px;
    font-size: 16px;
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    transition: ease-out;
    margin-bottom: 12px;
  }

  .logo {
    height: 30px;
  }

  .navigation {
    height: 100vh;
    top: 60px;
    padding: 20px 0;
    width: 100%;
    
    border: 1px solid #ff006b;
    border-radius: 4px;
    transition: 1s ease-in;
    z-index: 40; /* LOWER THAN DROPDOWN */
  }
  
  .navigation.showNav {
    padding: 20px 0;
    background-color: #ffffff;
    height: 100vh;
    transition: 1s ease-in;
    text-align: end;
    z-index: 100;
  }


  .dropdownContainer {
    position: relative;
    

  }
  
  .GetinTouch {
    font-size: 12px;
    padding: 5px 9px;
  }

  /* humberger Menu Button for Navigation*/
  .menu {
    --s: 20px; /* control the size */
    --c: black; /* the color */

    height: var(--s);
    aspect-ratio: 1;
    border: none;
    padding: 0;
    border-inline: calc(var(--s) / 2) solid #0000;
    box-sizing: content-box;
    --_g1: linear-gradient(var(--c) 20%, #0000 0 80%, var(--c) 0) no-repeat
      content-box border-box;
    --_g2: radial-gradient(
        circle closest-side at 50% 12.5%,
        var(--c) 95%,
        #0000
      )
      repeat-y content-box border-box;
    background: var(--_g2) left var(--_p, 0px) top,
      var(--_g1) left calc(var(--s) / 10 + var(--_p, 0px)) top,
      var(--_g2) right var(--_p, 0px) top,
      var(--_g1) right calc(var(--s) / 10 + var(--_p, 0px)) top;
    background-size: 20% 80%, 40% 100%;
    position: relative;
    clip-path: inset(0 25%);

    cursor: pointer;
    transition: background-position 0.3s var(--_s, 0.3s),
      clip-path 0s var(--_s, 0.6s);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .menu:before,
  .menu:after {
    content: "";
    position: absolute;
    border-radius: var(--s);
    inset: 40% 0;
    background: var(--c);
    transition: transform 0.3s calc(0.3s - var(--_s, 0.3s));
  }

  .menu:checked {
    clip-path: inset(0);
    --_p: calc(-1 * var(--s));
    --_s: 0s;
  }
  .menu:checked:before {
    transform: rotate(45deg);
  }
  .menu:checked:after {
    transform: rotate(-45deg);
  }
  .menu:focus-visible {
    clip-path: none;

    border: none;
    outline: 2px solid var(--c);
    outline-offset: 5px;
  }

  body {
    margin: 0;
    min-height: 100vh;
    display: grid;
    place-content: center;
  }
}
