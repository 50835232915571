/* Reporting-section */
@keyframes appear{
  from{
    opacity: 0;
    scale: 0.5;
  }
  to{
    opacity: 1;
    scale: 1;
  }
}

@keyframes cards{
  from{
    opacity: 0;
    transform:translateX(200px) ;
  }
  to{
    opacity: 1;
    transform:translateX(0px) ;
  }
}

.reporting-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 110px 50px;
  font-family: Gilroy-Bold;
  
}

.reporting-section h2 {
  font-size: 40px;
  font-family: Gilroy-Medium;
  margin-bottom: 20px;
  color: #000;
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.reporting-section p {
  font-size: 16px;
  color: #0e0e0e;
  margin-bottom: 40px;
  margin: 0 100px 40px 100px;
  text-align: center;
  animation: appear ease;
  animation-timeline: view();
  animation-range: entry 0% cover 60%;
}

.reporting-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.reporting-card {
  border-radius: 10px;
  padding: 30px;
  width: 300px;
  border: 1px solid rgba(52, 52, 52, 0.502);
  text-align: left;
  animation: cards linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.icon-image {
  font-size: 40px;
  color: #EA0063;
}

.card-icon {
  margin-bottom: 15px;
}

.reporting-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #000;
}

.reporting-card p {
  font-size: 14px;
  color: #0c0c0c;
  margin: 0;
  text-align: left;
}

/* Mobile View  ////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .reporting-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 10px;
    font-family: Gilroy-Bold;
    
  }
  .reporting-section h2 {
    font-size: 30px;
    font-family: Gilroy-Medium;
    margin-bottom: 10px;
    color: #000;
    text-align: center;
  }
  .reporting-section p {
    font-size: 15px;
    color: #0e0e0e;

    margin: 0 10px 40px 10px;
    text-align: center;
  }

  .reporting-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
  .reporting-card {
    border-radius: 10px;
    padding: 30px;
    width: 95%;
    border: 1px solid rgba(52, 52, 52, 0.502);
    text-align: left;
  }

  .reporting-card p {
    font-size: 14px;
    color: #0c0c0c;
    margin: 0;
    text-align: left;
  }
}
