.SignupForm2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: left;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  font-family: Gilroy-Medium;
  background-image: url('../../assets/texture.png');
  margin-top: 5px;
}

.formImage {
  width: 50%;
}

.brandContainer{
  width: 100%;
  
}

.brandContainer .inputField{
  width: 330px;
}

.formImage img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  max-width: 800px;
  object-fit: contain;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}


.formContainer2 {
  border: 2px solid grey;
  border-radius: 12px;
  margin: 40px 0;
  background-color: white;
  display: flex;
  padding: 16px;
  
  
}

.formContainer2 .formBox {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0 20px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
 
}


.formContainer2 .heading {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
 
  font-family: Gilroy-Bold;
}

.description {
  font-size: 12px;
 
  text-align: left;
}

.formContainer2 label {
  display: block;
  margin-bottom: 4px;
  text-align: left;

}


.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 7px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
 border: 1px solid rgba(83, 83, 83, 0.401);
}


.submitButton {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
 
  background-color: #000000;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-bottom: 12px;
}
.submitButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* CSS styles for the popup box */





.SignupForm2 .buttonContainer2 {
  display: flex; 
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 8px;
  font-family: Gilroy-Medium;
}




@media (max-width: 768px) {
  .form {
    padding: 15px;
  }

 
  .authButton,
  .submitButton {
    padding: 8px;
  }

  .buttonRow {
    flex-direction: column;
  }

  .authButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .authButton:last-child {
    margin-bottom: 0;
  }
}

/*
///   Laptop View
*/

@media (max-width: 1024px) {
 
  .inputText,
  .textareaText,
  .button {
    font-size: 16px;
  }
}


/*
///   Tab View
*/
@media (max-width: 768px) {
  .formContainer {
    padding: 24px;
  }

  .buttonContainer {
    flex-direction: column;
    gap: 16px;
  }

  .submitButton,
  .connectButton {
    width: 100%;
  }
}

/*
///   Mobile View
*/
@media (max-width: 480px) {
  .inputText,
  .textareaText,
  .button {
    font-size: 14px;
  }

  .formContainer {
    padding: 15px;
    width: 100%;
    z-index: 1;
    margin-bottom: 30px;
    background-color: #34d1ed7e;
  }

  .brandContainer .inputField{
    width: 100%;
  }

  .formImage {
    width: 100%;
  }

  .formImage img {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    justify-content: center;
  }

  .buttonContainer {
    gap: 12px;
  }
}
/* multiselect css*/
.multiSelect {
  width: 100%;
  margin-bottom: 7px;
 
}

.reactSelect {
 
 
  color: white;
}
.reactSelect__control .css-pzyrx-control{
  border-radius: 10px;
  background-color: #0056b3;
}
.reactSelect__control {
  border-radius: 10px;
  background-color: #73737304;
  color: white;
  border: none;
  box-shadow: none;
}


.reactSelect__control--is-focused {
  box-shadow: none;
}

.reactSelect__multi-value {
  
  color: white;
}

.reactSelect__multi-value__label {
  color: rgb(194, 37, 37);
}

.reactSelect__multi-value__remove {
  color: rgb(239, 36, 36);
  cursor: pointer;
}

.reactSelect__multi-value__remove:hover {
  background-color: #000000;
  color: white;
}
