/* src/components/LineChart/LineChart.module.css */
.chartContainer {
    width: 100%;
    margin: 0px auto;
    font-family: 'Gilroy-Medium';
    border-radius: 8px;
    padding: 20px;
    border: 1px solid rgba(55, 55, 55, 0.558);
  }
  
  .title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  