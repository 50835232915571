.CreatorContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CreatorHeroPage {
  margin: 50px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.CreatorHeroPage h1 {
  font-size: 60px;

  color: #000;
  margin-bottom: 20px;

  text-align: center;
  font-family: Gilroy-Medium;
}

.CreatorHeroPage p {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin: 0 200px 40px 200px;
}

.CreatorHeroPage .StartButton1 {
  background-color: #ff0066;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}

.StartButton:hover {
  background-color: #a3013f;
}

.GetInTouch {
  margin-top: 30px;
}

.GetInTouch p {
  font-size: 16px;
  color: #666;
}

.GetInTouch a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.GetInTouch a:hover {
  text-decoration: underline;
}
/* Brand Section//////////////////////////////////////////////*/

.BrandIcons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  font-family: Gilroy-Bold;
  margin: 20px 0;
  background: rgba(0, 68, 255, 0);
}
.brands-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.brand-logo img {
  width: 80px;
}

/* table View //////////////////////////////////////////*/
.TableView {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding: 50px 0;
  background: black;
  flex-direction: column;
}
.TableView p,
.TableView h1 {
  color: white;
  text-align: center;
}

.TableView h1 {
  margin-bottom: 30px;
  font-family: Gilroy-Medium;
}
.TableView img {
  width: 70%;
  margin: 0 100px;
}
.tableimg {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Metrix Box ????????????????????????????????????????????????????????????????????? */
.MetrixContainer {
  margin: 50px 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  background-color: #a3013f00;
}
.MetrixContainer h1 {
  margin-bottom: 20px;
  font-family: Gilroy-Medium;
}
.MetrixBox {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: #33333300;
  width: 100%;

  gap: 50px;
}
.metricCard {
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid rgba(91, 91, 91, 0.393);
  padding: 20px;
  text-align: center;
  max-width: 400px;
  height: 305px;
  transition: 0.3s ease-out;
}
.metricCard:hover {
  background-color: #ffffff;
}

.metricCard h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #000;
}

.metricCard p {
  color: #666;
  margin-bottom: 20px;
}

.metricImage {
  width: 100%;
  height: auto;
}

.metricImage img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Creator Says Section //////////////////////////////////////////////////////*************************************/
.BrandSaysContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 100px;
  flex-wrap: wrap;
  background: rgb(230, 230, 230);
}

.testimonial {
  width: 50%;
  height: 400px;
  padding: 20px;
  border-radius: 10px;
  transition: 0.3s ease;
}

.testimonial:hover {
  background-color: #e9e9e9;
}

.companyLogo {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.testimonialText {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.userDetails {
  display: flex;
  align-items: start;
  justify-content: left;
}
.userDetails div {
  display: flex;
  flex-direction: column;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial .userName {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.userTitle {
  font-size: 14px;
  color: #888;
}

/*  Dashbaord View left and right panel**/
.DashBoardView {
  display: flex;
  padding: 50px 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.LeftPanelDash {
  width: 50%;
  height: 100%;
  padding-right: 30px;
}

.shareButton {
  background-color: #ff0066;
  color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 10px;
  border: none;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}

.mainHeading {
  font-size: 50px;
  color: #000;
  font-family: Gilroy-Medium;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;

  color: #666;
  margin: 10px 0;
}

.features {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.featureItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.featureItem span {
  margin-right: 8px;
  color: #333;
  font-size: 18px;
}

.howItWorks {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.playButton {
  background-color: #ff0044;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.howItWorks span {
  font-size: 16px;
  color: #333;
}

/* Right Panel */
.RightPanelDash {
  width: 50%;

  background: rgb(238, 238, 238);
  padding: 30px;
  border-radius: 24px;
}
.RightPanelDash img {
  width: 100%;
}

/*Newsletter section ************************************************************************************** */
.Newsletter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  Mobile view ///////////////////////////////////////////////////////////////////*/
@media (max-width: 480px) {
  .CreatorHeroPage {
    /* Takes the full width of the viewport */

    padding: 10px; /* Adds padding to prevent content touching screen edges */
    margin: 40px auto; /* Center the content */
    height: auto;
  }

  .CreatorContainer {
    margin: 0 auto;
  }

  .CreatorHeroPage h1 {
    font-size: 30px;
  }
  .CreatorHeroPage p {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin: 0 10px 20px 10px;
  }
  /*  Dashboard Mobile View*/
  .DashBoardView {
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .LeftPanelDash {
    width: 100%;
    height: 100%;
    padding-right: 0px;
  }

  .shareButton {
    background-color: #ff0066;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 10px;
    border: none;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
  }

  /* Right Panel */
  .RightPanelDash {
    width: 100%;
    margin-top: 20px;
    background: rgb(238, 238, 238);
    padding: 30px;
    border-radius: 24px;
  }
  .RightPanelDash img {
    width: 100%;
  }
  

  .metricCard {
    height: 250px;
  }

  .CreatorSaysContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 40px;
    width: 100%;
    flex-wrap: wrap;
    background: rgb(142, 142, 142);
  }
  .testimonial {
    width: 90%;
    height: auto;
  }
}
