.dashContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px;
  font-family: "Gilroy", sans-serif;
}

.dashContainer p{
text-align: center;
font-family: Gilroy-Medium;
font-size: 15px;
}
.greet{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dashContainer img{
  width: 300px;
}

.dashButton {
  text-align: center;
}

.dashButton button {
  padding: 10px 18px;
  background-color: #ff006b;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .dashContainer img{
    width: 150px;
  }
  
  
.dashButton button {
  padding: 8px 16px;
  background-color: #ff006b;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 10px;
  font-family: "Gilroy", sans-serif;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

}