.SignupForm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background-image: linear-gradient(
    to left bottom,
    rgba(249, 82, 82, 0.212),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(245, 245, 245, 0),
    rgba(255, 255, 255, 0),
    rgba(245, 157, 245, 0.207)
  );
}

.formContainer {
  border-radius: 12px;
  border: 2px solid #e2e8f0;
  margin: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.formImage {
  width: 50%;
}
.formImage img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  max-width: 800px;
  object-fit: contain;
 
  
}



.formBox {
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: start;
  flex-direction: column;
}

.formContainer .formBox button {
  padding: 12px 24px;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.heading {
  font-size: 30px;
  font-weight: bold;

  margin-bottom: 10px;
}

.description {
  font-size: 14px;

  font-family: Gilroy-Medium;
  margin-bottom: 20px; /* Add space between description and input fields */
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px; /* Increase space between input fields */
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  outline: none;
  font-family: Gilroy-Medium;
}

.orText {
  width: 100%;
  text-align: center;

  font-family: Gilroy-Medium;
  margin-top: 10px;
  margin-bottom: 10px; /* Add space above and below OR text */
}

.continueButton {
  width: 100%;
  background-color: #ea0063;
  color: rgb(236, 236, 236);

  font-weight: bold;
  padding: 15px;
  border-radius: 10px;
}

.SignupForm .buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  font-family: Gilroy-Medium;
}

.buttonContainer img{
  width: 30px;
  border: 1px solid rgba(59, 59, 59, 0.349);
  padding: 4px;
  border-radius: 6px;
}

.buttonContainer img:hover{
  background-color: rgba(207, 207, 207, 0.533);
}

.NavContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  background: rgb(241, 241, 241);
  padding: 6px 14px;
  border-radius: 10px;
}

.NavContainer:hover {
  background-color: #bababa;
}
.NavSignup {
  text-decoration: none;
}

.NavSignup p {
  margin-bottom: 0;
  font-family: Gilroy-Bold;
}

@media (max-width: 768px) {
  .form {
    padding: 15px;
  }

  .inputField,
  .textarea,
  .authButton,
  .submitButton {
    padding: 8px;
  }

  .buttonRow {
    flex-direction: column;
  }

  .authButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .authButton:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .title,
  .asterisk,
  .inputText,
  .textareaText,
  .button {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .formContainer {
    padding: 24px;
  }

  .buttonContainer {
    flex-direction: row;
    gap: 16px;
  }

  .authButton {
    background-color: #f6f6f6;
    color: rgba(11, 11, 11, 0.997);
    padding: 15px;
  }
  .inputField {
    padding: 15px;
  }
}

/* 
////  Mobile View ////////////////////////////////////////////////////////////////////////////
*/
@media (max-width: 480px) {
  .formContainer {
    padding: 16px;
    width: 100%;
   
   
  }
  .formImage {
    width: 90%;
  }
  .formImage img {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    justify-content: center;
  }
  .buttonContainer {
    gap: 3px;
  }
}
