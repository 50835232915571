.dashboardContainer {
  padding: 20px 40px;
  font-family: Arial, sans-serif;
}
.chartContainer {
  flex: 3;
  border-radius: 8px;
  padding: 20px;
}

.performanceContainer {
  flex: 2;
  border-radius: 8px;
  padding: 20px;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboardHeader h1 {
  font-size: 24px;
  font-weight: bold;
}

.dateDownload {
  display: flex;
  align-items: center;
}

.dateRange {
  background-color: #f4f4f4;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 10px;
}

.downloadButton {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dashboardNav {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 4px;
}

.navItem {
  padding: 5px 10px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #888;
  border-radius: 6px;
  margin-right: 4px;
}

.navItem:hover {
  color: #000;
}

.navItem.active {
  background-color: #fff;
  font-weight: bold;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navItem:not(.active):hover {
  color: #333;
}

@media (max-width: 480px) {
  .dashboardContainer {
    padding: 5px 8px;
  }
  .dashboardHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 4px;
  }
}
