.reportsContainer {
    margin: 0 auto;
    padding: 20px;
   
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
}

p {
    color: #666;
    margin-bottom: 20px;
}

.influencerList {
    list-style-type: none;
    padding: 0;
}

.influencerItem {
    margin-bottom: 20px;
}

.influencerCard {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
}

.influencerCard:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.creatorImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 2px solid #ccc;
}

.influencerInfo {
    flex-grow: 1;
}

.influencerInfo h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
}

.influencerInfo p {
    font-size: 14px;
    color: #777;
    margin: 2px 0;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.deleteButton,
.viewProfileButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.viewProfileButton {
    background-color: #2196F3;
}

.deleteButton:hover {
    background-color: #e53935;
}

.viewProfileButton:hover {
    background-color: #1976D2;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.modalImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.closeModalButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 5px;
    font-size: 14px;
}

.closeModalButton:hover {
    background-color: #e53935;
}

@media (max-width: 600px) {
    .influencerCard {
        flex-direction: column;
        align-items: flex-start;
    }

    .creatorImg {
        margin-bottom: 10px;
    }

    .buttons {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .modalContent {
        width: 90%;
    }
}
