.analyticsContainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.header {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.header h1 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.campaignInfo {
  border-left: 4px solid #007bff;
  padding-left: 1rem;
}

.campaignInfo h2 {
  color: #007bff;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.dates {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  color: #666;
}

.platform {
  display: inline-block;
  background-color: #e3f2fd;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #1976d2;
  font-weight: 500;
  margin-top: 0.5rem;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.metricCard {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.metricCard:hover {
  transform: translateY(-5px);
}

.metricCard h3 {
  color: #666;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.topCreators {
  margin-bottom: 32px;
}

.topCreators h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.creatorsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
}

.creatorCard {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

.creatorHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.creatorInfo h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.creatorInfo p {
  font-size: 14px;
  color: #666;
}

.rank {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  background-color: #e3f2fd;
  color: #1976d2;
  border-radius: 4px;
}

.creatorMetrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.creatorMetrics > div {
  text-align: center;
}

.creatorMetrics p {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.creatorMetrics span {
  font-size: 16px;
  font-weight: 500;
}

.mainMetric {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
}

.averageMetrics,
.additionalMetrics {
  margin-top: 2rem;
}

.averageMetrics h2,
.additionalMetrics h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .analyticsContainer {
    padding: 1rem;
  }

  .header {
    padding: 1.5rem;
  }

  .metricsGrid {
    grid-template-columns: 1fr;
  }

  .dates {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.insightsSection {
  margin: 32px 0;
}

.insightsSection h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.insightsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.insightCard {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insightCard h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
}

.insightMetrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.insightMetrics > div {
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.insightMetrics p {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.insightMetrics span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #2196f3;
  margin-bottom: 4px;
}

.insightMetrics small {
  display: block;
  font-size: 12px;
  color: #888;
}

@media (max-width: 768px) {
  .insightsGrid {
    grid-template-columns: 1fr;
  }

  .insightMetrics {
    grid-template-columns: 1fr;
  }
}

.topCreatorsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filterControls {
  display: flex;
  align-items: center;
}

.filterSelect {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  background-color: white;
  font-size: 0.875rem;
}

.highlightedMetric {
  background-color: #f7fafc;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
