.LoginForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Gilroy-Bold;
  background-image: linear-gradient(
    to left bottom,
    rgba(249, 82, 82, 0.212),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(245, 245, 245, 0),
    rgba(255, 255, 255, 0),
    rgba(245, 157, 245, 0.207)
  );
}

.formImage {
  width: 50%;
}

.formImage img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: auto;
  max-width: 800px;
  object-fit: contain;
 
}



.ToggleForms {
  display: flex;
  flex-direction: column;
}

.toggleButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.toggleButtons button {
  width: 50%;
  padding: 5px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #ea0063;
  color: rgb(236, 236, 236);
  font-weight: bold;
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1em;
  font-weight: bold;
}

.switch {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 3px;
  display: flex;
  align-items: center;
}

.slider {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgb(78, 240, 226);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.sliderActive {
  transform: translateX(26px);
  background-color: #ff007f; /* Pink color */
}

.inactiveLabel {
  color: #888;
}

@media (max-width: 768px) {
  .form {
    padding: 15px;
  }

  .inputField,
  .textarea,
  .authButton,
  .submitButton {
    padding: 8px;
  }

  .buttonRow {
    flex-direction: column;
  }

  .authButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .authButton:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .title,
  .asterisk,
  .inputText,
  .textareaText,
  .button {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .formContainer {
    padding: 24px;
  }

  .buttonContainer {
    flex-direction: column;
    gap: 16px;
  }

  .submitButton,
  .connectButton {
    width: 100%; /* Ensures buttons use all the horizontal space */
  }
  .authButton {
    background-color: #f6f6f6;
    color: rgba(11, 11, 11, 0.997);
    padding: 15px;
  }
  .inputField {
    padding: 15px;
  }
}

/* 
  ////  Mobile View
  */
@media (max-width: 480px) {
  .formContainer {
    padding: 16px;
    width: 100%;
    z-index: 1;
    margin-top: -200px;
    margin-bottom: 30px;
    background-color: #34d1ed7e;
  }
  .formImage {
    width: 100%;
  }
  .formImage img {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    justify-content: center;
  }
  .buttonContainer {
    gap: 3px;
  }

  .inputField {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 50px;
    outline: none;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.436);
    font-family: Gilroy-Medium;
  }

  .authButton {
    background-color: #f6f6f6;
    color: rgba(11, 11, 11, 0.997);
    padding: 15px;
    font-family: Gilroy-Medium;
    font-size: 14px;
  }
  .authButton:last-child {
    font-size: 14px;
  }
}
