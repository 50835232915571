.ContactContainer {
  display: flex;
  flex-wrap: wrap;

  padding: 20px;
}
.ContactBox {
  width: 50%;

  padding: 20px;

  font-family: Gilroy-Medium;
}
.FormContainer {
  margin: 20px;
}

.heading {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 10px;
}

.subheading {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.field label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
}

.field select,
.field textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}
.field input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

.consent {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.checkboxField {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.checkboxField input {
  margin-right: 10px;
}

.submitButton {
  width: 100%;
  padding: 10px 20px;
  background-color: #ff006b;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: 0.4s ease;
}

.submitButton:hover {
  background-color: #a90147;
}

.ContactImage {
  padding: 10px;
  width: 50%;
  background-color: rgba(173, 173, 173, 0);
}

.ContactImage img {
  width: 100%;
}
/* Mobile view ////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media (max-width: 480px) {
  .ContactContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .ContactBox {
    width: 100%;

    padding: 20px;

    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.485);
    font-family: Gilroy-Medium;
  }
  .ContactImage {
    width: 100%;
    background-color: rgba(173, 173, 173, 0);
  }
}
